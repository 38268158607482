export const websiteName = "CimaFlix.lol";
export const domainName = "cimaflix";
export const googleAnaly = "";

export const moviesLinks = [
  {
    path: "/aflam-ajnabi",
    title: "افلام اجنبي",
    category: "افلام اجنبي",
  },
  {
    path: "/aflam-arab",
    title: "افلام عربى",
    category: "افلام عربي",
  },
  {
    path: "/aflam-hindi",
    title: "افلام هندى",
    category: "افلام هندى",
  },

  {
    path: "/aflam-turk",
    title: "افلام تركى",
    category: "افلام تركى",
  },
  {
    path: "/aflam-anime",
    title: "افلام كارتون",
    category: "افلام كارتون",
  },
  {
    path: "/aflam-asia",
    title: "افلام اسيوية",
    category: "افلام اسيوية",
  },
];

export const seriesLinks = [
  {
    path: "/serie-ajnabi",
    title: "مسلسلات اجنبي",
    category: "مسلسلات اجنبي",
  },
  {
    path: "/serie-arabi",
    title: "مسلسلات عربية",
    category: "مسلسلات عربية",
  },
  {
    path: "/serie-hindi",
    title: "مسلسلات هندية",
    category: "مسلسلات هندية",
  },
  {
    path: "/serie-asia",
    title: "مسلسلات اسيوية",
    category: "مسلسلات اسيوية",
  },
  {
    path: "/serie-turk",
    title: "مسلسلات تركية",
    category: "مسلسلات تركية",
  },
  // {
  //   path: "/serie-anime",
  //   title: "مسلسلات كرتون",
  //   category: "مسلسلات كرتون",
  // },
];
